import React from "react";
import { motion } from "framer-motion";

export default function Info() {
  return (
    <section class="info">
      <div class="auto__container">
        <div class="info__inner agenda-info">
          <motion.div
            animate={{
              x: [50, 0],
              opacity: [0, 1],
            }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
          >
            <div class="info__inner-title">
              <h2>2025 Agenda Snapshot</h2>
            </div>
          </motion.div>

          <div class="info__inner-main">
            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
              >
                <h4>DAY 1 | April 30, 2025</h4>
                <ul>
                  <li>
                    <div className="time">9:00 AM – 2:00 PM</div>
                    <div className="info-container">
                      <p>VIP Check-In & Executive Networking</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">2:00 PM</div>
                    <div className="info-container">
                      <p>Opening Remarks & Breakout Icebreaker</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">3:30 PM</div>
                    <div className="info-container">
                      <p>Event Kickoff</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">4:00 PM</div>
                    <div className="info-container">
                      <p>Welcome Champagne Toast</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">5:00 PM</div>
                    <div className="info-container">
                      <p>Industry Happy Hour: Connect with the Best in TA</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">6:00 PM</div>
                    <div className="info-container">
                      <p>Leadership Dinner</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">8:00 PM</div>
                    <div className="info-container">
                      <p>Evening Experience</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>

            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <h4>DAY 2 | May 1, 2025</h4>
                <ul>
                  <li>
                    <div className="time">8:00 AM</div>
                    <div className="info-container">
                      <p>Power Breakfast & Peer Roundtables</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:00 AM</div>
                    <div className="info-container">
                      <p>
                        Navigating Today’s Labor Market: Adapting to Rapid
                        Change
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">10:00 AM</div>
                    <div className="info-container">
                      <p>
                        Expert Panel: Mastering Operational Excellence in
                        High-Volume Hiring
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">11:00 AM</div>
                    <div className="info-container">
                      <p>Breakroom Session: Employer Branding at Scale</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">11:45 AM</div>
                    <div className="info-container">
                      <p>Networking Lunch: Connecting Strategy & Execution</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">1:00 PM</div>
                    <div className="info-container">
                      <p>
                        Exclusive Preview: Emerging Innovations in Product &
                        Technology
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">2:15 PM</div>
                    <div className="info-container">
                      <p>
                        Strategic Networking & Executive Recharge (Choose Your
                        Experience)
                      </p>
                      <p>
                        • Golf Experience: Build relationships on the green.
                        <br />• Luxury Spa Retreat: Recharge while networking in
                        a relaxed setting.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">6:00 PM</div>
                    <div className="info-container">
                      <p>
                        Private Happy Hour: Exclusive Conversations with
                        ZipRecruiter’s Board
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">7:00 PM</div>
                    <div className="info-container">
                      <p>Executive Dinner: Big Ideas, Bigger Impact</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">8:30 PM</div>
                    <div className="info-container">
                      <p>Evening Experience: Unwind, Connect & Celebrate</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>

            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                <h4>DAY 3 | May 2, 2025</h4>
                <ul>
                  <li>
                    <div className="time">8:00 AM</div>
                    <div className="info-container">
                      <p>Breakfast & Final Connections</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:15 AM</div>
                    <div className="info-container">
                      <p>Closing Fireside Chat with Special Guest</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">10:30 AM</div>
                    <div className="info-container">
                      <p>Final Reflections with ZipRecruiter CEO Ian Siegel</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">11:30 AM</div>
                    <div className="info-container">
                      <p>Farewell Lunch: Dine In or Grab & Go</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button-container info">
        <a
          href="https://cvent.me/1eReq3"
          target="_blank"
          className="button primary"
        >
          REGISTER NOW
        </a>
      </div>
    </section>
  );
}
